import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import Layout from "../components/layout"

import Hero from "../components/hero"
import Apps from "../components/apps"
import BlogPreview from "../components/blog-preview"

export default () => (
  <Layout>
    <GatsbySeo
      title="Welcome"
      bodyAttributes={{
        class: "bg-black",
        style: {},
      }}
    />

    <Hero />
    <Apps />
    <BlogPreview />

    {/*<div className="relative bg-white overflow-hidden">*/}
    {/*  <div className="max-w-screen-xl mx-auto ">*/}
    {/*    <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">*/}
    {/*      <svg*/}
    {/*        className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"*/}
    {/*        fill="currentColor"*/}
    {/*        viewBox="0 0 100 100"*/}
    {/*        preserveAspectRtio="none"*/}
    {/*      >*/}
    {/*        <polygon points="50,0 100,0 50,100 0,100" />*/}
    {/*      </svg>*/}

    {/*      <div className="relative pt-6 px-4 sm:px-6 lg:px-8">*/}
    {/*        <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">*/}
    {/*          <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">*/}
    {/*            <div className="flex items-center justify-between w-full md:w-auto">*/}
    {/*              <a href="#">*/}
    {/*                <img*/}
    {/*                  className="h-8 w-auto sm:h-6"*/}
    {/*                  src="/images/logo-black.svg"*/}
    {/*                  alt=""*/}
    {/*                />*/}
    {/*              </a>*/}
    {/*              <div className="-mr-2 flex items-center md:hidden">*/}
    {/*                <button*/}
    {/*                  type="button"*/}
    {/*                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"*/}
    {/*                >*/}
    {/*                  <svg*/}
    {/*                    className="h-6 w-6"*/}
    {/*                    stroke="currentColor"*/}
    {/*                    fill="none"*/}
    {/*                    viewBox="0 0 24 24"*/}
    {/*                  >*/}
    {/*                    <path*/}
    {/*                      stroke-linecap="round"*/}
    {/*                      stroke-linejoin="round"*/}
    {/*                      stroke-width="2"*/}
    {/*                      d="M4 6h16M4 12h16M4 18h16"*/}
    {/*                    />*/}
    {/*                  </svg>*/}
    {/*                </button>*/}
    {/*              </div>*/}
    {/*            </div>*/}
    {/*          </div>*/}
    {/*          <div className="hidden md:block md:ml-10 md:pr-4">*/}
    {/*            <a*/}
    {/*              href="/apps"*/}
    {/*              className="font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"*/}
    {/*            >*/}
    {/*              Apps*/}
    {/*            </a>*/}
    {/*            <a*/}
    {/*              href="/bots"*/}
    {/*              className="ml-8 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"*/}
    {/*            >*/}
    {/*              Bots*/}
    {/*            </a>*/}
    {/*            <a*/}
    {/*              href="#"*/}
    {/*              className="ml-8 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"*/}
    {/*            >*/}
    {/*              Blog*/}
    {/*            </a>*/}
    {/*            <a*/}
    {/*              href="#"*/}
    {/*              className="ml-8 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"*/}
    {/*            >*/}
    {/*              Open Source*/}
    {/*            </a>*/}
    {/*          </div>*/}
    {/*        </nav>*/}
    {/*      </div>*/}

    {/*      <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">*/}
    {/*        <div className="sm:text-center lg:text-left">*/}
    {/*          <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">*/}
    {/*            Data to enrich your*/}
    {/*            <br className="xl:hidden" />*/}
    {/*            <span className="text-indigo-600">online business</span>*/}
    {/*          </h2>*/}
    {/*          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">*/}
    {/*            Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui*/}
    {/*            lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat*/}
    {/*            fugiat aliqua.*/}
    {/*          </p>*/}
    {/*          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">*/}
    {/*            <div className="rounded-md shadow">*/}
    {/*              <a*/}
    {/*                href="#"*/}
    {/*                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"*/}
    {/*              >*/}
    {/*                Get started*/}
    {/*              </a>*/}
    {/*            </div>*/}
    {/*            <div className="mt-3 sm:mt-0 sm:ml-3">*/}
    {/*              <a*/}
    {/*                href="#"*/}
    {/*                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"*/}
    {/*              >*/}
    {/*                Live demo*/}
    {/*              </a>*/}
    {/*            </div>*/}
    {/*          </div>*/}
    {/*        </div>*/}
    {/*      </div>*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*  <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">*/}
    {/*    <img*/}
    {/*      className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"*/}
    {/*      src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"*/}
    {/*      alt=""*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*</div>*/}
    {/*<Hero />*/}
    {/*<Features />*/}
  </Layout>
)
