import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Pill from "@components/pill"

const AppsPage = () => {
  const data = useStaticQuery(graphql`
    query AppsPageQuery {
      site {
        siteMetadata {
          description
        }
      }
      allAppsJson {
        edges {
          node {
            slug
            name
            url
            image
            description
            soon
            beta
          }
        }
      }
    }
  `)

  const apps = data.allAppsJson.edges

  const renderApps = () => {
    let index = 0
    return apps.map(node => {
      index++
      const app = node.node
      return (
        <a
          key={app.slug}
          href={app.url}
          style={{
            "--sal-delay": "2s", //1000 + (250 * index) / 1000 + "s",
            "--sal-duration": "1s",
          }}
          className="hover:no-underline"
        >
          <div className="max-w-sm rounded-lg overflow-hidden shadow-2xl m-4 sm:m-2 bg-gray-900 hover:bg-gray-800 transform hover:scale-110 transition duration-300 ease-in-out">
            {/*<div className=" overflow-hidden  rounded-lg">*/}
            {/*  {app.soon && <Pill text="Soon" color="teal" />}*/}
            {/*  {app.beta && (*/}
            {/*    <div className="bg-blue-100 text-blue-800 transform text-center fixed px-16 pb-2 pt-16 -rotate-45 -mt-7 -ml-16">*/}
            {/*      Beta*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*</div>*/}
            <img
              className="h-40 mt-12 mb-8 mx-auto"
              src={app.image}
              alt={app.name}
            />
            <div className="px-8 py-6">
              {app.soon && <Pill text="Soon" color="pink" />}
              {app.beta && (
                <Pill text="Beta available!" color="yellow" className="mb-10" />
              )}

              <div className="mt-1">
                <span className="font-bold inline-block text-white text-2xl mb-2 font-mono tracking-tight">
                  {app.name}
                </span>
              </div>
              <div className="text-gray-500 text-base">{app.description}</div>
            </div>
          </div>
        </a>
      )
    })
  }

  return (
    <div className="py-12 bg-blackXX">
      <div className="text-center px-4">
        {/*<p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">*/}
        {/*  thefunbots*/}
        {/*</p>*/}
        <h3
          data-sal="zoom-in"
          data-sal-delay="250"
          data-sal-duration="1000"
          data-sal-easing="ease-in-out"
          className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl sm:leading-10 font-mono tracking-tighter"
        >
          Amazing robot crafted apps
          {/*<a className="text-red-600" href="/bots">*/}
          {/*  robots*/}
          {/*</a>*/}
        </h3>
        {/*<p*/}
        {/*  data-sal="fade"*/}
        {/*  data-sal-easing="ease-in-out"*/}
        {/*  className="mt-4 max-w-2xl text-xl leading-7 text-gray-400 mx-auto"*/}
        {/*  style={{*/}
        {/*    "--sal-delay": "1.2s",*/}
        {/*    "--sal-duration": "1s",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam*/}
        {/*  voluptatum cupiditate veritatis in accusamus quisquam.*/}
        {/*</p>*/}
      </div>

      <div className="container my-12 mx-auto px-4 md:px-12">
        <div className="flex flex-wrap -mx-1 lg:-mx-4 justify-center">
          {renderApps()}
        </div>
      </div>
    </div>
  )
}

export default AppsPage
