import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import Pill from "@components/pill"

export default props => {
  const data = useStaticQuery(graphql`
    query blogPreviewQuery {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 3
        filter: { fields: { draft: { eq: false } } }
      ) {
        edges {
          node {
            id
            excerpt
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              path
              title
              image {
                childImageSharp {
                  fluid {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
              }
              categories
              tags
              author {
                name
                avatar
              }
            }
          }
        }
      }
    }
  `)

  const posts = data.allMarkdownRemark.edges

  return (
    <div className="relative bg-white px-4 py-6 overflow-hidden sm:px-6 sm:py-8 lg:p-14 xl:p-22 text-center">
      <div className="container mx-auto">
        <h1>
          From the <Link to="blog">Blog</Link>
        </h1>
        <p className="text-gray-500">Latest from our blog</p>

        <div className="lg:grid grid-cols-3 gap-8 mt-4 md:mt-24">
          {posts.map(edge => (
            <div
              key={edge.node.frontmatter.path}
              className="text-gray-900 text-left mb-4 sm:mb-12 "
            >
              <Link to={edge.node.frontmatter.path}>
                {edge.node.frontmatter.image ? (
                  <Image
                    fluid={edge.node.frontmatter.image.childImageSharp.fluid}
                    alt={edge.node.frontmatter.title}
                    className="hover:scale-110 hover:shadow-2xl transform transition duration-200 mx-auto"
                  />
                ) : (
                  <div className="bg-gray-100 ">
                    <img
                      className="hover:scale-110 transform transition duration-200 mx-auto w-full"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAYCAYAAAEdX5QvAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAL6ADAAQAAAABAAAAGAAAAADYnYBbAAAAK0lEQVRYCe3QAQ0AAADCoPdPbQ43iEBhwIABAwYMGDBgwIABAwYMGDBwMzARzQABylwcUQAAAABJRU5ErkJggg=="
                      alt={edge.node.frontmatter.title}
                    />
                  </div>
                )}
              </Link>
              <div className="mt-4">
                {/*<div>*/}
                {/*  {edge.node.frontmatter.categories.length &&*/}
                {/*    edge.node.frontmatter.categories.map(item => (*/}
                {/*      <Pill text={item} color="blue" />*/}
                {/*    ))}*/}
                {/*</div>*/}
                <Link
                  to={edge.node.frontmatter.path}
                  className=" text-gray-900 transition duration-200"
                >
                  <p className="font-bold tracking-tighter text-lg font-mono">
                    {edge.node.frontmatter.title}
                  </p>
                </Link>
                <p className="text-md text-gray-500 mt-4">
                  {edge.node.excerpt}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
